import { toast } from 'react-toastify';

//requests APIs
export const executeRequest = async (
  action,
  msgSuccess = 'Operação realizada com sucesso',
  msgError = 'A operação falhou, tente novamente em breve',
  setIsLoading = () => {}, // Função padrão vazia (opcional | isLoading)
  triggerRefresh = () => {} // Função padrão vazia (opcional | Hook)
) => {
  setIsLoading(true);
  try {
    const response = await action();
    setIsLoading(false);

    // Se a resposta não tiver status, consideramos que a operação foi bem-sucedida
    const isSuccessful = response?.status === 204 || (response?.status >= 200 && response?.status < 300);

    // Verifica se a resposta inclui o status ou se data está presente para considerar sucesso
    if (response || isSuccessful) {
      msgSuccess !== null && toast.success(msgSuccess);
      triggerRefresh && triggerRefresh();
      return response; // Retorna o valor do response se a solicitação for bem-sucedida
    } else {
      throw new Error('Erro na operação');
    }
  } catch (error) {
    setIsLoading(false);
    msgError !== null && toast.error(msgError);
    throw error; // Lança o erro para permitir o tratamento adicional, se necessário
  }
};
