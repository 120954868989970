import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { Accordion, Box, Flex, Grid } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

import CardFile from './CardFile';
import CardGeneralInformation from './CardGeneralInformation';
import StepDocument from './StepDocument';

const yup = require('yup');

const ExpeditionUploadDocument = ({ clientOptions, onClose, load = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);

  const [generalData, setGeneralData] = useState({});

  const onSubmit = async (formData) => {
    requests
      .uploadExpeditionDocument(formData)
      .then(() => {
        load();
        toast.success(`Documento(s) de expedição enviado(s) com sucesso.`);
        onClose();
      })
      .catch((e) => {
        toast.error(`Problema ao enviar documento(s) de expedição, tente novamente.`);
      });
  };

  return (
    <Grid py="15px" px="10px" maxW={'780px'} w={'100%'}>
      <Box>
        <Flex direction="column" height={{ base: '300px', md: '500px' }} overflowY="auto" mt="0.625rem">
          <Accordion defaultIndex={[0]} index={step}>
            <ScreenLoader isLoading={isLoading}>
              <Flex gap="0.625rem" direction="column">
                <StepDocument
                  title="Dados gerais"
                  step={step}
                  stepIndex={0}
                  onNext={() => {
                    setStep(0);
                  }}>
                  {step === 0 && (
                    <CardGeneralInformation
                      setStep={setStep}
                      clientOptions={clientOptions}
                      formData={generalData}
                      setFormData={setGeneralData}
                    />
                  )}
                </StepDocument>

                <StepDocument
                  title="Documentos"
                  stepIndex={1}
                  step={step}
                  onNext={() => {
                    setStep(1);
                  }}>
                  {step === 1 && (
                    <CardFile
                      accept=".xml, .pdf, .png, .jpeg"
                      multiple={true}
                      entity={generalData}
                      requestAction={onSubmit}
                      additional={[
                        {
                          label: 'Modalidade',
                          group: 'modality',
                          name: 'modality',
                          required: true,
                          options: [
                            { value: 'romaneio', label: 'Romaneio' },
                            { value: 'canhoto', label: 'Canhoto' },
                          ],
                        },
                      ]}
                    />
                  )}
                </StepDocument>
              </Flex>
            </ScreenLoader>
          </Accordion>
        </Flex>
      </Box>
    </Grid>
  );
};

export default ExpeditionUploadDocument;
