import React, { useCallback, useEffect, useState } from 'react';

import { MdUploadFile } from 'react-icons/md';
import Select from 'react-select';

import { Box, Button, Flex, FormControl, IconButton, Text, useDisclosure } from '@chakra-ui/react';

import InputDefault from '../../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../../components/Form/Label/LabelDefault';
import ModalUpload from '../../../../../components/Modal/Upload/ModalUpload';
import api from '../../../../../services/api';
import requests from '../../../../../services/requests';

const CardMainData = ({
  values,
  setFieldValue,
  errors,
  setFieldError,
  validatedForm,
  clientList,
  setClientList,
  filterOptions = [],
  setStep,
  docsTripSelected,
  setDocsTripSelected,
  setDocsTrajectoriesSelected,
  geolocationList,
  setGeolocationList,
  processSelected,
  setProcessSelected,
  setShowAlert,
  clientsEntity,
}) => {
  const [cteFilesOptions, setCteFilesOptions] = useState([]);
  const [isUploadCte, setIsUploadCte] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [processOptions, setProcessOptions] = useState([]);

  const modalityOptions = [
    { value: 'APP_MOTORISTA', label: 'Aplicativo do motorista' },
    { value: 'FRETE_RASTREAVEL', label: 'Rastreável' },
    { value: 'FRETE_NAO_RASTREAVEL', label: 'Não rastreável' },
    { value: 'MANUAL', label: 'Viagem manual' },
  ];

  const shippingCompanyNameOptions = [
    { value: 'VENDEMMIA', label: 'Vendemmia' },
    { value: 'OUTRO', label: 'Outro' },
  ];

  const typeExternalOptions = [
    { value: 'OPENTECH', label: 'Código Opentech (integração para rastreio)' },
    { value: 'NOTA_FISCAL', label: 'Número de nota fiscal' },
    { value: 'ORDEM_SERVICO', label: 'Número de ordem de serviço' },
    { value: 'OUTROS', label: 'Outros' },
  ];

  const typeTransportOptions = [
    { value: 'GENERAL', label: 'Transporte geral' },
    { value: 'FREIGHT_RETURN', label: 'Transporte de frete-retorno' },
    { value: 'PORT', label: 'Transporte portuário' },
    { value: 'INTERNATIONAL', label: 'Transporte internacional' },
  ];

  const subTypeTransportOptions = [
    { value: 'REMOVAL', label: 'Remoção' },
    { value: 'DEVOLUTION', label: 'Devolução' },
    { value: 'MOVEMENT', label: 'Movimentação' },
  ];

  const typeMandatoryFileOptions = [
    { value: 'CTE', label: 'CT-e (Conhecimento de Transporte)' },
    { value: 'NFSE', label: 'NFS-e (Nota fiscal de serviços)' },
    { value: 'DTA', label: 'DTA (Documento de Trânsito Aduaneiro)' },
    { value: 'DTC', label: 'DTC (Documento de Trânsito de Container)' },
    { value: 'PLMI', label: 'PLMI (Protocolo de liberação de mercadoria ou bem importado)' },
    { value: 'ROMANEIO', label: 'Romaneio' },
  ];

  let listDocsSelect = [];

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .uploadDriverFreightCteFile(docs, config)
        .then((response) => {
          listDocsSelect.push(response?.fileIdentifier);
          loadCteFiles([...listDocsSelect, response?.fileIdentifier]);
          resolve();
          onClose();
          setIsUploadCte(true);
        })
        .catch((e) => {
          reject();
        });
    });
  };

  const loadCteFiles = async (selected = []) => {
    try {
      const response = await requests.listDriverFreightCte({});
      const aux = response.map((data) => ({
        label: data.name,
        value: data.fileIdentifier,
        processes: data.processes,
        ctes: data.ctes,
      }));

      setCteFilesOptions(aux);

      if (selected.length > 0) {
        const selectedOption = aux.filter((option) => selected.includes(option.value));
        if (selectedOption.length > 0) {
          handleMappingSelectedOptions(selectedOption);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async () => {
    if (await validatedForm(values, setFieldError, 'mainSchema')) {
      setStep(1);
    } else {
    }
  };

  const handleMappingSelectedOptions = useCallback(
    async (selectedOptions) => {
      const trip = [];
      const cteOptions = [];
      const aux = [];
      let selectedClients = [];
      let merchandise = 0;
      let freight = 0;
      let processes = [];
      let processesList = [];
      let weight = 0;
      let hasCte = false;
      let clientsProcessOptions = [];

      if (selectedOptions.length > 0) {
        setDocsTripSelected(selectedOptions);

        await Promise.all(
          selectedOptions.map(async (option) => {
            if (option.ctes && option.ctes.length > 0) {
              hasCte = true;

              await Promise.all(
                option.ctes.map(async (cte) => {
                  const clientsTrip = [];
                  const clientsProcess = [];
                  const merchandiseCte = parseFloat(cte?.merchandise) || 0;
                  const freightCte = parseFloat(cte?.freight) || 0;
                  const weightCte = parseFloat(cte?.weight) || 0;

                  merchandise += merchandiseCte;
                  freight += freightCte;
                  weight += weightCte;

                  filterOptions.forEach((item) => {
                    const clients = item?.options?.filter((client) => client.documentNumber === cte.client);
                    if (clients && clients.length > 0) {
                      clientsTrip.push(...clients);

                      clients.forEach((client) => {
                        if (!selectedClients.some((selectedClient) => selectedClient.documentNumber === client.documentNumber)) {
                          clientsProcess.push(client.identifier);
                          selectedClients.push(client);
                        }
                      });
                    }
                  });

                  clientsProcessOptions = await loadProcessList(clientsProcess);
                  setClientList(selectedClients);

                  if (cte.trajectories) {
                    await Promise.all(
                      cte.trajectories.map(async (trajectory) => {
                        const position = await requests
                          .getGeolocationByAddress(trajectory.address)
                          .then((data) => data.position)
                          .catch(() => ({ latitude: 0, longitude: 0 }));

                        const existsTripIndex = trip.findIndex(
                          (item) => item.latitude === position.latitude && item.longitude === position.longitude
                        );

                        if (existsTripIndex === -1) {
                          trip.push({
                            type: { value: trajectory.type, label: trajectory.type },
                            cteNumber: [
                              {
                                label: cte?.name,
                                value: cte?.identifier || '',
                              },
                            ],
                            clients: clientsTrip,
                            address: trajectory.address || '',
                            observation: '',
                            latitude: position.latitude || 0,
                            longitude: position.longitude || 0,
                          });

                          if (position.latitude !== 0 && position.longitude !== 0) {
                            aux.push({
                              address: trajectory?.address,
                              position: {
                                latitude: position.latitude || 0,
                                longitude: position.longitude || 0,
                              },
                            });
                          }
                        } else if (trip[existsTripIndex]) {
                          trip[existsTripIndex].cteNumber = [
                            ...trip[existsTripIndex].cteNumber,
                            {
                              label: cte?.name,
                              value: cte?.identifier || '',
                            },
                          ];
                        }

                        if (!cteOptions.some((option) => option.value === cte?.identifier)) {
                          cteOptions.push({
                            label: cte?.name,
                            value: cte?.identifier || '',
                          });
                        }
                      })
                    );
                  }
                })
              );
            }

            if (option.processes) {
              option.processes.forEach((item) => {
                if (!processes.includes(item)) {
                  processes.push(item);
                  const processFiltered = clientsProcessOptions.find((option) => option.label === item);
                  processesList.push(processFiltered);
                }
              });
            }
          })
        );

        setShowAlert(!hasCte);

        if (cteOptions.length > 0) {
          setFieldValue('trip', trip);
          setDocsTrajectoriesSelected(cteOptions);
        } else {
          setDocsTrajectoriesSelected(selectedOptions);
        }

        setGeolocationList(aux);
        setFieldValue('main.processes', processesList);
        setProcessSelected(processes);
      } else {
        setDocsTripSelected([]);
        setClientList([]);
        setDocsTrajectoriesSelected([]);
        setGeolocationList({});
        setProcessSelected([]);
        setShowAlert(true);
      }

      if (trip?.length == 0) {
        setFieldValue('trip', [
          {
            type: '',
            cteNumber: '',
            clients: '',
            address: '',
            observation: '',
            latitude: 0,
            longitude: 0,
          },
        ]);
      }

      setFieldValue('freight.merchandiseValue', merchandise);
      setFieldValue('freight.freightValue', freight);
      setFieldValue('freight.weight', weight);
    },
    [
      setClientList,
      setDocsTripSelected,
      setDocsTrajectoriesSelected,
      setGeolocationList,
      setProcessSelected,
      setShowAlert,
      setFieldValue,
      filterOptions,
    ]
  );

  const handleClientChange = useCallback(
    async (value) => {
      const selectedValues = Array.isArray(value) ? value : [value];
      const lastIndex = selectedValues.length - 1;
      const clientsIdentifier = [];

      const selectedChildren = selectedValues.flatMap((item, key) => {
        clientsIdentifier.push(item.identifier);
        if (key === lastIndex) {
          return [
            item,
            ...item.children.map((child) => {
              clientsIdentifier.push(child.identifier);
              return {
                label: ` ${'\u00A0'} - ${child.name} (${child.documentNumberFormatted})`,
                value: child.identifier,
              };
            }),
          ];
        } else {
          return item;
        }
      });

      const trip = await Promise.all(
        values?.trip?.map(async (item) => ({
          type: item?.type ?? '',
          cteNumber: item?.cteNumber ?? '',
          clients: '',
          address: item?.address ?? '',
          observation: item?.observation ?? '',
          latitude: item?.latitude ?? 0,
          longitude: item?.longitude ?? 0,
        }))
      );

      setFieldValue('trip', trip);
      setClientList(selectedChildren);

      if (selectedValues?.length == 0) {
        setProcessOptions([]);
        setProcessSelected([]);
        setFieldValue('main.processes', []);
      } else {
        loadProcessList(clientsIdentifier);
      }
    },
    [values, setFieldValue, setClientList]
  );

  const handleProcessChange = useCallback(async (value) => {
    const selectedValues = Array.isArray(value) ? value : [value];
    let processes = [];

    selectedValues.flatMap((item, key) => {
      processes.push(item.label);
    });

    setFieldValue('main.processes', value);
    setProcessSelected(processes);
  });

  const loadProcessList = useCallback((clientsIdentifier = []) => {
    return new Promise((resolve, reject) => {
      const formattedParams = formatClientParams(clientsIdentifier);
      if (clientsIdentifier.length > 0) {
        api
          .get('process/list/all?page_size=10000' + formattedParams, {
            headers: {
              Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
            },
          })
          .then((response) => {
            var processList = [];

            response.data.forEach((item) => {
              processList.push({
                value: item?.identifier,
                label: item?.code,
              });
            });

            if (response.data.length === 0) {
              // setNoOptionsMessage('Nenhum resultado encontrado');
            }

            setProcessOptions(processList);
            resolve(processList);
          })
          .catch((error) => {
            // Optionally handle the error and reject the promise
            console.error('Error loading process list', error);
            reject(error);
          })
          .finally(() => {
            // setIsLoadingProcess(false);
          });
      } else {
        setProcessOptions([]);
      }
    });
  });

  function formatClientParams(clientIds) {
    return clientIds.map((id) => `&clients[]=${id}`).join('');
  }

  useEffect(() => {
    loadCteFiles();
  }, []);

  useEffect(() => {
    if (clientsEntity?.length > 0) {
      loadProcessList(clientsEntity);
    }
  }, [clientsEntity]);

  return (
    <>
      <Flex direction="column" gap="20px">
        <Flex flexWrap="wrap" gap="20px" mx="2px">
          <FormControl w="full">
            <LabelDefault name="main.typeTransport" text="Tipo da viagem" />
            <Select
              options={typeTransportOptions}
              width={{ base: '7rem', md: '8.125rem' }}
              id="main.typeTransport"
              name="main.typeTransport"
              value={values.main.typeTransport}
              onChange={(option) => setFieldValue('main.typeTransport', option)}
              className="input-register"
              placeholder="Selecione"
            />
            {errors?.typeTransport && <Text className="error-message-error">{errors.typeTransport}</Text>}
          </FormControl>
          {values.main.typeTransport?.value == 'PORT' && (
            <FormControl w="full">
              <LabelDefault name="main.subTypeTransport" text="Tipo do transporte portuário" />
              <Select
                options={subTypeTransportOptions}
                width={{ base: '7rem', md: '8.125rem' }}
                id="main.subTypeTransport"
                name="main.subTypeTransport"
                value={values.main.subTypeTransport}
                onChange={(option) => setFieldValue('main.subTypeTransport', option)}
                className="input-register"
                placeholder="Selecione"
              />
              {errors?.subTypeTransport && <Text className="error-message-error">{errors.subTypeTransport}</Text>}
            </FormControl>
          )}
          <FormControl w="full">
            <LabelDefault name="main.typeMandatoryFile" text="Tipo de documento mandatário da viagem" />
            <Select
              options={typeMandatoryFileOptions}
              width={{ base: '7rem', md: '8.125rem' }}
              id="main.typeMandatoryFile"
              name="main.typeMandatoryFile"
              value={values.main.typeMandatoryFile}
              onChange={(option) => setFieldValue('main.typeMandatoryFile', option)}
              className="input-register"
              placeholder="Selecione"
            />
            {errors?.typeMandatoryFile && <Text className="error-message-error">{errors.typeMandatoryFile}</Text>}
          </FormControl>
          <FormControl w={{ md: '50%' }}>
            <Flex direction="column" gap="8px">
              <LabelDefault
                name="driverFreightFiles"
                text={
                  <Flex gap="4px" alignItems="center">
                    <Text>Arquivos da viagem</Text>
                  </Flex>
                }
              />
              <Select
                width={{ base: '7rem', md: '8.125rem' }}
                id="driverFreightFiles"
                name="driverFreightFiles"
                value={docsTripSelected}
                options={cteFilesOptions}
                isMulti={true}
                onChange={(selectedOptions) => {
                  handleMappingSelectedOptions(selectedOptions);
                }}
                className="input-register"
                placeholder="Selecione"
                components={{
                  ClearIndicator: ({ innerProps }) => (
                    <Flex
                      textColor="red"
                      fontSize="14px"
                      fontWeight="bold"
                      px="3px"
                      cursor="pointer"
                      _hover={{ opacity: '0.8', transition: '0.3s' }}
                      {...innerProps}>
                      Limpar
                    </Flex>
                  ),
                }}
              />
              {errors?.driverFreightFiles && <Text className="error-message-error">{errors.driverFreightFiles}</Text>}
            </Flex>
          </FormControl>
          <FormControl w={{ md: '45%' }}>
            <Flex direction="column" gap="8px">
              <LabelDefault
                name="file"
                text={
                  <Flex gap="4px" alignItems="center">
                    <Text>Upload de arquivos</Text>
                    <Text fontSize="small"> (opcional)</Text>
                  </Flex>
                }
              />
              <Box>
                <IconButton
                  bgColor="transparent"
                  border="1px"
                  borderColor={isUploadCte ? '#6C48C2' : '#70707033'}
                  icon={<MdUploadFile size={24} color={isUploadCte ? '#6C48C2' : '#0F0A1D66'} />}
                  onClick={() => {
                    onOpen();
                  }}
                />
              </Box>
            </Flex>
          </FormControl>
          <FormControl w="full">
            <Flex direction="column" gap="8px">
              <LabelDefault name="clients" text="Empresa" />
              {filterOptions &&
                filterOptions.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Select
                        className="input-register"
                        placeholder="Selecione"
                        options={item.options}
                        getOptionValue={(option) => `${option.value} ${option.documentNumber}`}
                        getOptionLabel={(option) => `${option.label}`}
                        isMulti={item.isMultiple}
                        name="clientsMaster"
                        value={item?.options?.filter((option) => {
                          return clientList?.some((client) => client.value === option.value);
                        })}
                        onChange={(option) => handleClientChange(option)}
                        components={{
                          ClearIndicator: ({ innerProps }) => (
                            <Flex
                              textColor="red"
                              fontSize="14px"
                              fontWeight="bold"
                              px="3px"
                              cursor="pointer"
                              _hover={{ opacity: '0.8', transition: '0.3s' }}
                              {...innerProps}>
                              Limpar
                            </Flex>
                          ),
                        }}
                      />
                    </React.Fragment>
                  );
                })}
              {errors?.client && <Text className="error-message-error">{errors.client}</Text>}
            </Flex>
          </FormControl>
          <FormControl w="full">
            <LabelDefault name="main.processes" text="Processo" />
            <Select
              options={processOptions}
              width={{ base: '7rem', md: '8.125rem' }}
              id="main.processes"
              name="main.processes"
              value={values.main.processes}
              isMulti={true}
              onChange={(option) => handleProcessChange(option)}
              // onChange={(option) => setFieldValue('main.processes', option)}
              className="input-register"
              placeholder="Selecione"
            />
            {errors?.processes && <Text className="error-message-error">{errors.processes}</Text>}
          </FormControl>
          <FormControl w="full">
            <LabelDefault name="main.modality" text="Modalidade" />
            <Select
              options={modalityOptions}
              width={{ base: '7rem', md: '8.125rem' }}
              id="main.modality"
              name="main.modality"
              value={values.main.modality}
              onChange={(option) => setFieldValue('main.modality', option)}
              className="input-register"
              placeholder="Selecione"
            />
            {errors?.modality && <Text className="error-message-error">{errors.modality}</Text>}
          </FormControl>
          <FormControl w={{ md: '50%' }}>
            <Flex direction="column" gap="8px">
              <LabelDefault
                name="main.typeExternalCode"
                text={
                  <Flex gap="4px" alignItems="center">
                    <Text>Tipo Código Externo</Text>
                    <Text fontSize="small"> (opcional)</Text>
                  </Flex>
                }
              />
              <Select
                width={{ base: '7rem', md: '8.125rem' }}
                id="main.typeExternalCode"
                name="main.typeExternalCode"
                value={values.main.typeExternalCode}
                options={typeExternalOptions}
                onChange={(option) => setFieldValue('main.typeExternalCode', option)}
                className="input-register"
                placeholder="Selecione"
              />
              {errors?.typeExternalCode && <Text className="error-message-error">{errors.typeExternalCode}</Text>}
            </Flex>
          </FormControl>
          <FormControl w="100%">
            <LabelDefault
              name="main.externalCode"
              text={
                <Flex gap="4px" alignItems="center">
                  <Text>Código externo</Text>
                  {typeof values.main.typeExternalCode === 'string' && values.main?.modality?.value !== 'FRETE_RASTREAVEL' && (
                    <Text fontSize="small"> (opcional)</Text>
                  )}
                </Flex>
              }
            />
            <InputDefault
              placeholder="Preencha o código externo"
              name="main.externalCode"
              className="input-register"
              background="transparent"
              setFieldValue={setFieldValue}
              value={values.main.externalCode}
            />
            {errors?.externalCode && <Text className="error-message-error">{errors.externalCode}</Text>}
          </FormControl>
          <Box w="full">
            <LabelDefault name="main.shippingCompanyName" text="Transportadora" />
            <Select
              options={shippingCompanyNameOptions}
              width={{ base: '7rem', md: '8.125rem' }}
              id="main.shippingCompanyName"
              name="main.shippingCompanyName"
              value={values.main.shippingCompanyName}
              onChange={(option) => {
                setFieldValue('main.shippingCompanyName', option);
                setFieldValue('main.shippingCompanyNameDescription', '');
              }}
              className="input-register"
              placeholder="Selecione"
            />
            {errors?.shippingCompanyName && <Text className="error-message-error">{errors.shippingCompanyName}</Text>}
          </Box>

          {values.main.shippingCompanyName?.value == 'OUTRO' && (
            <FormControl w="100%">
              <LabelDefault
                name="main.shippingCompanyNameDescription"
                text={
                  <Flex gap="4px" alignItems="center">
                    <Text>Descrição</Text>
                    <Text fontSize="small"> (opcional)</Text>
                  </Flex>
                }
              />
              <InputDefault
                placeholder="Preencha a transportadora"
                name="main.shippingCompanyNameDescription"
                background="transparent"
                setFieldValue={setFieldValue}
                value={values.main.shippingCompanyNameDescription}
              />
            </FormControl>
          )}
        </Flex>
      </Flex>

      <Button
        mt="60px"
        variant="primary"
        onClick={(decision) => {
          if (decision) {
            onSubmit();
          }
        }}>
        <Text fontStyle="paragraph">Salvar</Text>
      </Button>

      <ModalUpload
        title="Gestão de viagens"
        subtitle="Upload de Documentos (CT-e, MDF-e, NF-e)"
        accept=".xml, .pdf,"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        requestAction={sendUpload}
        multiple={false}
        nameForm="driver_freight_file_upload"
        additional={[
          {
            label: 'Modalidade',
            group: 'modality',
            name: 'mod',
            required: true,
            options: [
              { value: 'MDFE_XML', label: 'XML do MDF-e (Manifesto Eletrônico de Documentos Fiscais)' },
              { value: 'MDFE_PDF', label: 'PDF do MDF-e (Manifesto Eletrônico de Documentos Fiscais)' },
              { value: 'CTE_XML', label: 'XML do CT-e (Conhecimento de Transporte)' },
              { value: 'CTE_PDF', label: 'PDF do CT-e (Conhecimento de Transporte)' },
              { value: 'NF_XML', label: 'XML da NFS-e (Nota fiscal de serviços)' },
              { value: 'NF_PDF', label: 'PDF da NFS-e (Nota fiscal de serviços)' },
              { value: 'DTA', label: 'DTA (Documento de Trânsito Aduaneiro)' },
              { value: 'DTC', label: 'DTC (Documento de Trânsito de Container)' },
              { value: 'PLMI', label: 'PLMI (Protocolo de liberação de mercadoria ou bem importado)' },
              { value: 'ROMANEIO', label: 'Romaneio' },
            ],
          },
        ]}
      />
    </>
  );
};

export default CardMainData;
