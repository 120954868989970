import React, { useState } from 'react';

import { MdDeleteOutline, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Box, Collapse, Flex, Image, Table, Tbody, Td, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import api from '../../../../assets/svgs/warehouse/api.svg';
import ftp from '../../../../assets/svgs/warehouse/ftp.svg';
import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../services/requests';
import { limitChars } from '../../../../utils/strings';
import ShortenText from '../../../../utils/text/format/shortenText';

const PickingPackingList = ({ entity, count, onSave }) => {
  let profileRole = localStorage.getItem('profile-role');

  const [load, setLoad] = useState(false);
  const { isOpen, onToggle } = useDisclosure();

  const Icon = isOpen ? <MdKeyboardArrowDown color="#707070" /> : <MdKeyboardArrowRight color="#707070" />;

  const handleDelete = async () => {
    setLoad(true);
    try {
      await requests.deletePickingPack(entity.identifier);
      toast.success('Picking & Packing de nota ' + entity.nuNota + ' removido com sucesso!');
      onSave();
    } catch (error) {
      toast.error('Erro ao excluir a nota, tente novamente!');
    } finally {
      setLoad(false);
    }
  };

  const handleColor = (text) => {
    switch (text.toUpperCase()) {
      case 'IMPORTADO':
        return 'status-100';

      case 'AG. FORMAÇÃO DE ROMANEIO/ONDA':
        return 'status-100';

      case 'AG. SEPARAÇÃO':
        return 'status-100';

      case 'SEPARAÇÃO INICIADA':
        return 'status-200';

      case 'SEPARAÇÃO CONCLUÍDA':
        return 'status-300';

      case 'CONFERÊNCIA CONCLUÍDA':
        return 'status-400';

      case 'ENVIADO PARA FATURAMENTO':
        return 'status-400';

      case 'FATURADO':
        return 'status-500';

      case 'CONFERÊNCIA INICIADA':
        return 'status-600';

      case 'COLETA INICIADA':
        return 'status-600';

      case 'CANCELADO':
        return 'red';

      case 'DIGITADO':
        return 'gray-300';

      case 'QUARENTENA':
        return 'yellow-500';

      case 'PROCESSADO':
        return 'status-700';

      case 'A':
        return '#2ECC71';

      case 'B':
        return '#F1C40F';

      case 'C':
        return '#E74C3C';

      default:
        return '';
    }
  };

  const getIcon = (origin) => {
    switch (origin) {
      case 'API':
        return api;
      default:
        return ftp;
    }
  };

  const getLabel = (origin) => {
    switch (origin) {
      case 'API':
        return 'Importação via API Sênior';
      default:
        return 'Importação via FTP Sênior';
    }
  };

  return (
    <>
      <Tr onClick={onToggle} className="border" cursor="pointer">
        <Td>
          <Box>{Icon}</Box>
        </Td>
        {profileRole === 'ROLE_SYSTEM_ADMIN' && (
          <Td>
            <Box>
              <Tooltip label={getLabel(entity?.originImport)}>
                <Image
                  src={getIcon(entity?.originImport)}
                  color="status-600"
                  alt="Origem do registro"
                  ml="1"
                  width="26px"
                  height="26px"
                />
              </Tooltip>
            </Box>
          </Td>
        )}
        <Td>
          <Box>{entity?.idNotafiscal ?? '-'}</Box>
        </Td>
        <Td>
          <Box>
            <Flex
              align="center"
              borderRadius="15px"
              justifyContent="center"
              bgColor={handleColor(entity?.statusNf)}
              color="white"
              w={'9rem'}
              h="2rem">
              {entity?.statusNf?.length > 13 ? (
                <Tooltip label={entity.statusNf}>{limitChars(entity.statusNf, 13) ?? '-'}</Tooltip>
              ) : (
                entity?.statusNf ?? '-'
              )}
            </Flex>
          </Box>
        </Td>
        <Td>
          <Box>{entity?.statusNf === 'SEPARAÇÃO CONCLUÍDA' ? entity?.usuarioSeparacao ?? '-' : entity?.usuarioConferencia ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.stateCodeFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.nuNota ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.serieNf ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.idPedido ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.classTpPedido ?? '-'}</Box>
        </Td>
        <Td>
          <Box minW="300px">
            {entity?.nomeEntrega?.length > 50 ? (
              <Tooltip label={entity.nomeEntrega}>{limitChars(entity.nomeEntrega, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeEntrega ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Box>{entity?.ufDestinatario ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.recipientCity ?? '-'}</Box>
        </Td>
        <Td>
          <Box minW="300px">
            {entity?.nomeDepositante?.length > 50 ? (
              <Tooltip label={entity.nomeDepositante}>{limitChars(entity.nomeDepositante, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeDepositante ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Box minW="300px">
            {entity?.nomeTransportadora?.length > 50 ? (
              <Tooltip label={entity.nomeTransportadora}>{limitChars(entity.nomeTransportadora, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeTransportadora ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Box>{entity?.dtCadastroFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.dtInicioSeparacaoFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.dtFimSeparacaoFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.dtInicioConferenciaFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.dtFimConferenciaFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.qtVolumesFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.cubagemM3Formatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.valorNfFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.weightFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box minW="300px">
            {entity?.observacao?.length > 50 ? (
              <Tooltip label={<div dangerouslySetInnerHTML={{ __html: entity?.observacao }} />}>
                <span>{limitChars(entity?.observacao, 50)}</span>
              </Tooltip>
            ) : (
              entity?.observacao ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Box>{entity?.updatedAtFormatted ?? '-'}</Box>
        </Td>
        {profileRole !== 'ROLE_CLIENT' && (
          <Td>
            <Box>
              <DefaultModal
                label="Excluir Picking & Packing"
                ctaButton={<MdDeleteOutline size={25} color="#E74C3C" />}
                title={`Certeza que deseja excluir a nota ${entity?.nuNota}?`}
                loading={load}
                subtitle="Esta ação não poderá ser desfeita."
                buttonColor="red"
                txtButton="Excluir"
                loadingText="Excluindo"
                callback={async (decision) => {
                  if (decision) {
                    await handleDelete();
                  }
                }}
              />
            </Box>
          </Td>
        )}
      </Tr>
      <Tr w="full">
        <Td
          p="0"
          colSpan={16}
          border="none"
          style={{
            padding: 0,
          }}>
          <Collapse in={isOpen} animateOpacity>
            <Box bgColor="#F9F9F9" m="1.2rem">
              <Table>
                <Thead border="0" borderBottom="1px" h={50}>
                  <Tr>
                    <Th>Código</Th>
                    <Th>Descrição</Th>
                    <Th>Quantidade</Th>
                    <Th>Valor</Th>
                    <Th>Lote Indústria</Th>
                    <Th>Número de série</Th>
                    <Th>Curva ABC</Th>
                  </Tr>
                </Thead>
                <Tbody borderBottom="1px">
                  {entity?.itens && entity?.itens?.length > 0 ? (
                    entity?.itens?.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          <Tr>
                            <Td>{item?.codProduto ?? '-'}</Td>
                            <Td>{item?.descricaoProduto ?? '-'}</Td>
                            <Td>{item?.qtdeExpedidaFormatted ?? '-'}</Td>
                            <Td>{item?.totalValueFormatted ?? '-'}</Td>
                            <Td>{item?.loteIndustrial ?? '-'}</Td>
                            <Td>
                              <ShortenText value={item?.infoEspecifica} maxLength={25} />
                            </Td>
                            <Td>
                              <Box>
                                <Flex
                                  align="center"
                                  justifyContent="center"
                                  borderRadius="15px"
                                  bgColor={handleColor(item?.inAbc)}
                                  color="white"
                                  w="3rem"
                                  h="2rem">
                                  {item?.inAbc ?? '-'}
                                </Flex>
                              </Box>
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td colSpan={6}>
                        <Flex justify="center" my="25px">
                          Nenhum dado encontrado para os parâmetros filtrados.
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};

export default PickingPackingList;
