import md5 from 'md5';
import moment from 'moment';

import api from './api';

const isClient = typeof window === 'object';

const simplePostBlobRequest = async function (url, data, cacheKey, cancelTokenSource) {
  if (cacheKey && cacheKey.length > 0) {
    let cache = window.localStorage.getItem('cache-' + cacheKey);
    if (cache) {
      return new Promise((resolve, reject) => {
        resolve(JSON.parse(cache));
      });
    }
  }

  const response = await api.post(
    url,
    data,
    {
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
      },
    },
    cancelTokenSource
  );
  return response;
};

const simpleGetBlobRequest = async function (url, cacheKey, cancelTokenSource) {
  if (cacheKey && cacheKey.length > 0) {
    let cache = window.localStorage.getItem('cache-' + cacheKey);
    if (cache) {
      return new Promise((resolve, reject) => {
        resolve(JSON.parse(cache));
      });
    }
  }
  const response = await api.get(
    url,
    {
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
      },
    },
    cancelTokenSource
  );
  return response;
};

const simpleGetRequest = async function (url, shouldCache = false, cancelTokenSource = null, fullRes = false) {
  let cacheKey = md5(url);

  if (shouldCache) {
    let cache = window.localStorage.getItem('cache-' + cacheKey);
    if (cache) {
      return new Promise((resolve, reject) => {
        resolve(JSON.parse(cache));
      });
    }
  }

  const response = await api.get(
    url,
    {
      headers: {
        Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
      },
    },
    cancelTokenSource
  );

  if (shouldCache && cacheKey && cacheKey.length > 0) {
    window.localStorage.setItem('cache-' + cacheKey, JSON.stringify(response.data));
  }

  return fullRes ? response : response.data;
};

const simpleGetWithoutHeader = async function (url, shouldCache = false, cancelTokenSource = null) {
  let cacheKey = md5(url);

  if (shouldCache) {
    let cache = window.localStorage.getItem('cache-' + cacheKey);
    if (cache) {
      return new Promise((resolve, reject) => {
        resolve(JSON.parse(cache));
      });
    }
  }

  const response = await api.get(url, {}, cancelTokenSource);

  if (shouldCache && cacheKey && cacheKey.length > 0) {
    window.localStorage.setItem('cache-' + cacheKey, JSON.stringify(response.data));
  }

  return response.data;
};

const simpleDeleteRequest = async function (url) {
  const response = await api.delete(url, {
    headers: {
      Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
    },
  });
  return response.data;
};

const simplePostRequest = async function (url, data) {
  const response = await api.post(url, data, {
    headers: {
      Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
    },
  });
  return response.data;
};

const completePostRequest = async function (url, data) {
  const response = await api.post(url, data, {
    headers: {
      Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
    },
  });
  return response;
};

const simplePutRequest = async function (url, data) {
  const response = await api.put(url, data, {
    headers: {
      Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
    },
  });
  return response.data;
};

const uploadFiles = async function (url, data, config = {}) {
  const response = await api.post(url, data, {
    ...config,
    headers: {
      Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
    },
  });
  return response.data;
};

const generateFilterString = function (data) {
  if (!data) {
    return '';
  }

  // Handles search string
  var str = '';
  if (data.search && data.search.length > 0) {
    // Se enviado o search, todos os outros filtros são desconsiderados
    const searchWithoutHashtag = data?.search?.replace(/#/g, '');
    str += 'search=' + searchWithoutHashtag + '&';

    if (data.sortProcessComment) {
      str += 'sort=' + data.sortProcessComment + '&';
    }
  }

  if (data?.searchOveridesFilters) {
    str += 'searchOveridesFilters=true&';
  }

  // Handles date search
  if (data.period) {
    if (data.period.startsAt) {
      str += 'startsAt=' + data.period.startsAt + '&';
    }

    if (data.period.endsAt) {
      str += 'endsAt=' + data.period.endsAt + '&';
    }

    if (data?.filters?.period) {
      delete data.filters.period;
    }
  } else {
    if (data.startsAt) {
      str += 'startsAt=' + data.startsAt + '&';

      if (data?.filters?.period?.startsAt) {
        delete data.filters.period.startsAt;
      }
    }
    if (data.endsAt) {
      str += 'endsAt=' + data.endsAt + '&';

      if (data?.filters?.period?.endsAt) {
        delete data.filters.period.endsAt;
      }
    }
  }

  if (typeof data.parent === 'number') {
    str += 'parent=' + data.parent + '&';
  }

  // Handles all filters
  if (data.filters) {
    for (let key in data.filters) {
      let filter = data.filters[key];
      let field = key;

      if (typeof filter === 'undefined') {
        continue;
      }

      if (key === 'selectedStatus') {
        field = 'status';
      } else if (key === 'period') {
        if (filter.length > 0) {
          str += 'startsAt=' + moment(filter[0]).format('YYYY-MM-DD') + '&';
          str += 'endsAt=' + moment(filter[1]).format('YYYY-MM-DD') + '&';
        }
      } else if (field === 'selectedType') {
        field = 'type';
      } else if (field === 'ownership') {
        field = 'ownership';
      } else if (field === 'modalities') {
        field = 'modalities';
      }

      if (typeof filter === 'string' || (typeof filter === 'number' && field !== 'search')) {
        str += field + '=' + filter + '&';
      } else if (typeof filter === 'bool') {
        str += field + '=' + filter[i] + '&';
      } else if (filter?.length > 0 && key !== 'period') {
        for (let i in filter) {
          if (field != filter[i]) {
            str += field + '[]=' + filter[i] + '&';
          }
        }
      }
    }
  }

  if (data.sortProcessComment) {
    str += 'sort=' + data.sortProcessComment + '&';
  }

  if (data.client) {
    str += 'client=' + data.client + '&';
  }

  if (data.cteNumber) {
    str += 'cteNumber=' + data.cteNumber + '&';
  }

  if (data.statusUser) {
    str += 'statusUser=' + data.statusUser + '&';
  }

  if (data.nfeNumber) {
    str += 'nfeNumber=' + data.nfeNumber + '&';
  }

  if (data.type_storage) {
    str += 'type_storage=' + data.type_storage + '&';
  }

  if (data.periodPhotos) {
    str += 'periodPhotos=' + data.periodPhotos + '&';
  }

  if (data.statusPedido) {
    str += 'statusPedido=' + data.statusPedido + '&';
  }

  if (data.statusGrafico) {
    str += 'statusGrafico=' + data.statusGrafico + '&';
  }

  if (data.additionalStatusGrafico) {
    str += 'additionalStatusGrafico=' + data.additionalStatusGrafico + '&';
  }

  if (data.situation) {
    str += 'situation=' + data.situation + '&';
  }

  if (data.dashboard) {
    str += 'dashboard=' + data.dashboard + '&';
  }

  if (data.documentNumber) {
    str += 'documentNumber=' + data.documentNumber + '&';
  }

  if (data.codeTaxInquiry) {
    str += 'code=' + data.codeTaxInquiry + '&';
  }

  if (data.statusTaxInquiry) {
    str += 'status=' + data.statusTaxInquiry + '&';
  }

  // Handles sorting
  if (data.sort) {
    if (data.sort.column) {
      str += 'sort=' + data.sort.column + '&';
    }
    if (data.sort.direction) {
      str += 'direction=' + data.sort.direction + '&';
    }
  }

  if (data.sortColumn) {
    str += 'sort=' + data.sortColumn + '&';
  }

  if (data.sortDirection) {
    str += 'direction=' + data.sortDirection + '&';
  }

  if (data.order) {
    if (data.order.order_campo && data.order.order) {
      str += 'order_campo=' + data.order.order_campo + '&';
      str += 'order=' + data.order.order + '&';
    }
  }

  return str;
};

const generatePaginationString = function (page) {
  var str = '';
  if (page) {
    str += 'page=' + page + '&';
  }

  return str;
};

const generatePageSizeString = function (pageSize) {
  var str = '';
  if (pageSize) {
    str += 'page_size=' + pageSize + '&';
  }

  return str;
};

const generateSortString = function (sort) {
  var str = '';
  if (sort) {
    str = '&sort=' + sort.sort + '&direction=' + sort.direction;
  }

  return str;
};

const requestFreightByStatus = function (status, page, pageSize, filterString) {
  let pageSizeString = generatePageSizeString(pageSize);
  let paginationString = generatePaginationString(page);
  return simpleGetRequest(`/adm/driver-freight/list/kanban?status[]=${status}&${paginationString}${pageSizeString}${filterString}`);
};

//utils > actions
const createExportFile = (endpoint, filters, data) => {
  let filterString = generateFilterString(filters);
  return simplePostBlobRequest(`${endpoint}${filterString && `?` + filterString}?${new Date().getTime()}`, data);
  // return completePostRequest(`${endpoint}${filterString && `?` + filterString}`, data);
};

const getExportFile = (endpoint, filters) => {
  let filterString = generateFilterString(filters);

  return simpleGetBlobRequest(`${endpoint}${filterString && `?` + filterString}?${new Date().getTime()}`);
};

const saveImportFile = (endpoint, data, config) => {
  return uploadFiles(endpoint, data, config);
};

export { createExportFile, getExportFile, saveImportFile };

const requests = {
  getApiUrl() {
    return process.env.REACT_APP_API_URL;
  },
  getAuthUrl() {
    return process.env.REACT_APP_AUTH_URL;
  },
  // Generic
  wait() {
    return simpleGetRequest(`/profile/wait`);
  },
  get(url) {
    return simpleGetRequest(url);
  },
  post(url, data) {
    return simplePostRequest(url, data);
  },
  // auth
  logout() {
    return simpleDeleteRequest(`/profile/logout`);
  },

  getPopup() {
    return simpleGetRequest(`/interface/popup/check`);
  },
  postPopupRead(identifier) {
    return simplePostRequest(`/interface/popup/${identifier}/read`);
  },
  // Dashboards
  dashboardProcessSla(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(`/dashboard/process/sla?${filterString}${paginationString}&page_size=50`);
  },
  dashboardProcessByStage(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/dashboard/process/stage?${filterString}`);
  },
  dashboardProcessByChannel(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/dashboard/process/channel?${filterString}`);
  },
  dashboardProcessByModality(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/dashboard/process/modality?${filterString}`);
  },
  dashboardProcessFob(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/dashboard/process/fob-sum?${filterString}`);
  },
  dashboardProcessBilling(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/dashboard/process/billing-sum?${filterString}`);
  },
  atualizationsExport(fileModality = 'all', filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/adm/data-update/${fileModality}/qtt?${filterString}`);
  },
  atualizationsQtt(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/adm/data-update/qtt?${filterString}`);
  },
  dashboardBoardings(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/dashboard/process/boardings?${filterString}`);
  },
  dashboardClientTax(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/dashboard/client/tax?${filterString}`);
  },
  mapPositions(processIdentifier, filters) {
    let identifierString = processIdentifier ? `identifier=${processIdentifier}` : '';
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/dashboard/process/positions?${identifierString}${filterString}`);
  },
  processMapPositions(identifier) {
    return simpleGetRequest(`/process/${identifier}/positions`);
  },
  carbonFootprint(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/carbon-footprint/kpi/total?${filterString}`);
  },

  // Armazém - Picking Packing ou Expedição
  dashboardPickingPack(filters, type, fullRes = false) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/pickingepacking/metrics/${type}?${filterString}`, false, null, fullRes);
  },
  remittanceAnalysisExpedition(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/pickingepacking/remittance?${filterString}`);
  },
  listPickingPack(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/pickingepacking/list?${filterString}${paginationString}&page_size=15`);
  },

  // Driver information
  addDriverInformation(data) {
    return simplePostRequest(`/information/new`, data);
  },
  uploadFileDriverInformation(identifier, data) {
    return simplePostRequest(`/open/information/${identifier}/file/upload`, data);
  },
  deleteFileDriverInformation(identifier, fileIdentifier) {
    return simpleDeleteRequest(`/open/information/${identifier}/file/${fileIdentifier}/remove`);
  },
  editDriverInformation(identifier, data) {
    return simplePostRequest(`/information/${identifier}/edit`, data);
  },
  showDriverInformation(identifier) {
    return simpleGetRequest(`/information/${identifier}/show`);
  },
  changeStatusDriverFreight(identifier, transition) {
    return simplePostRequest(`/adm/driver-freight/${identifier}/change-status/${transition}`);
  },
  changeStatusDriverFreightFile(identifier, fileIdentifier, status) {
    return simplePostRequest(`/adm/driver-freight/${identifier}/file/${fileIdentifier}/change-status/${status}`);
  },
  listDriverFreightKanban(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/adm/driver-freight/list?${filterString}${paginationString}&page_size=50`);
  },
  listDriverFreightCte(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/adm/driver-freight/cte/file/list?${filterString}&page_size=9999`);
  },
  listDriverFreight(filters, page) {
    let filterString = generateFilterString(filters);
    let selected = generateFilterString({
      filters: { ...filters?.filters },
    });
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/adm/driver-freight/list?${filterString}${paginationString}${selected}`);
  },
  listCtes(filters, page) {
    let filterString = generateFilterString(filters);

    let selected = generateFilterString({
      filters: { ...filters?.filters },
    });

    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/adm/driver-freight/cte/list?${filterString}${paginationString}${selected}`);
  },
  listRealtime(filters, page) {
    let filterString = generateFilterString(filters);
    let pageSizeString = generatePageSizeString(10);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/adm/driver-freight/realtime/list?${filterString}${paginationString}${pageSizeString}`);
  },
  addDriverFreight(data, status) {
    return simplePostRequest(`/adm/driver-freight/new/${status}`, data);
  },
  editDriverFreight(identifier, data) {
    return simplePostRequest(`/adm/driver-freight/${identifier}/edit`, data);
  },
  deleteDriverFreight(identifier) {
    return simpleDeleteRequest(`/adm/driver-freight/${identifier}/remove`);
  },
  showDriverFreightFiles(identifier) {
    return simpleGetRequest(`/adm/driver-freight/${identifier}/file/list`);
  },
  showDriverFreight(identifier) {
    return simpleGetRequest(`/adm/driver-freight/${identifier}/show`);
  },
  showEventsDriverFreight(identifier, modality = 'app') {
    return simpleGetRequest(`/adm/driver-freight/${identifier}/events/show/${modality}`);
  },
  showCheckpointsDriverFreight(identifier) {
    return simpleGetRequest(`/adm/driver-freight/${identifier}/checkpoints/show`);
  },
  percentageCompletionDriverFreight(identifier) {
    return simpleGetRequest(`/adm/driver-freight/${identifier}/percentage`);
  },
  showDriver(identifier) {
    return simpleGetRequest(`/adm/driver/${identifier}/show`);
  },
  // Vehicles
  listVehicles(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/adm/vehicle/list?${pageSizeString}${filterString}${paginationString}`);
  },
  changeStatusVehicle(identifier, action) {
    return simpleGetRequest(`/adm/vehicle/${identifier}/${action}`);
  },
  deleteVehicle(identifier) {
    return simpleDeleteRequest(`/adm/vehicle/${identifier}/remove`);
  },
  // Armazém - Estoque
  dashboardEstoque(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/estoque/metrics?${filterString}`);
  },
  listEstoque(filters, page) {
    let paginationString = generatePaginationString(page);
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/estoque/list?${filterString}${paginationString}&page_size=15`);
  },
  savePhotosUploads(identifier, data, config) {
    return uploadFiles(`/estoque/file/${identifier}/upload`, data, config);
  },
  getFilesListByEstoque(filters, identifier, page) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/estoque/file/${identifier}/list?${filterString}`);
  },
  uploadPalletPosition(data, config) {
    return uploadFiles(`/wms/stock-position-consolidation/import`, data, config);
  },
  getStockConsolidation(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/wms/stock-position-consolidation/report/current?${filterString}`);
  },
  getStockHistory(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/wms/stock-position-consolidation/report/timeline?${filterString}`);
  },
  // Armazém - Recebimento
  dashboardRecebimento(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/recebimento/metrics?${filterString}`);
  },
  listRecebimento(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/recebimento/list?${filterString}${paginationString}&page_size=15`);
  },
  // Freight
  listFreights(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/driver-freight/list?${filterString}${paginationString}${pageSizeString}`);
  },

  // Armazém - Aging de estoque
  getAgingTotalPerFamily(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/wms/stock-aging/total?${filterString}`);
  },

  getPercentagePerPeriod(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/wms/stock-aging/percent?${filterString}`);
  },

  getAgingReport(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/wms/stock-aging/report?${filterString}`);
  },

  addFreightComment(identifier, data) {
    return simplePostRequest(`/driver-freight/${identifier}/comment/send`, data);
  },
  getFreightComments(identifier) {
    return simpleGetRequest(`/driver-freight/${identifier}/comment/list?`);
  },
  getFreightPositions(identifier) {
    return simpleGetRequest(`/driver-freight/positions/${identifier}/list?`);
  },
  removeFreightComment(identifier) {
    return simpleDeleteRequest(`/driver-freight/${identifier}/comment/remove`);
  },
  changeFreightVisibility(identifier, data) {
    return simplePostRequest(`/driver-freight/${identifier}/client-visibility`, data);
  },
  // Requests
  requestByCode(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/adm/request/count-by-status?${filterString}`);
  },
  requestByRole(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/adm/request/count-by-role?${filterString}`);
  },
  requestLogs(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(`/adm/request/list?${filterString}${paginationString}`);
  },
  requestRankingByUser(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(`/adm/request/ranking-by-user?${filterString}${paginationString}`);
  },
  requestSum(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(`/adm/request/sum?${filterString}${paginationString}`);
  },
  requestRankingByRoute(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(`/adm/request/ranking-by-route?${filterString}${paginationString}`);
  },
  // Aditional Fields
  listAditionalFields(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/process/additional-field/list?page_size=15&${filterString}${paginationString}${pageSizeString}`);
  },
  getAdditionalFieldLabels(identifier) {
    return simpleGetRequest(`/process/additional-field-value/${identifier}/get-labels`);
  },
  listLogApis(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/adm/request/list?page_size=15&${filterString}${paginationString}${pageSizeString}`);
  },
  listCountLogApis(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/adm/request/count&${filterString}`);
  },
  addAditionalField(data) {
    return simplePostRequest(`/process/additional-field/new`, data);
  },
  showAditionalField(identifier) {
    return simpleGetRequest(`/process/additional-field/${identifier}/show`);
  },
  editAditionalField(identifier, data) {
    return simplePostRequest(`/process/additional-field/${identifier}/edit`, data);
  },
  deleteAditionalField(identifier) {
    return simpleDeleteRequest(`/process/additional-field/${identifier}/remove`);
  },
  // Clients
  listClients(filters, page = '1', pageSize = '15', clientsModality = 'all') {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/client/list/${clientsModality}?${pageSizeString}&${filterString}${paginationString}`, true);
  },
  showSlaClient(identifier) {
    return simpleGetRequest(`/client/${identifier}/sla/list`);
  },
  editSlaClient(identifier, data) {
    return simplePostRequest(`/client/${identifier}/sla/edit`, data);
  },
  showClient(identifier) {
    return simpleGetRequest(`/client/${identifier}/show`);
  },
  deleteClient(identifier) {
    return simpleDeleteRequest(`/client/${identifier}/remove`);
  },
  savePickingPackFile(data, config) {
    return uploadFiles(`/pickingepacking/file/upload`, data, config);
  },
  deletePickingPack(identifier) {
    return simpleDeleteRequest(`/pickingepacking/${identifier}/remove`);
  },
  deleteReceipt(identifier) {
    return simpleDeleteRequest(`/recebimento/${identifier}/remove`);
  },
  reativeClient(identifier) {
    return simpleGetRequest(`/client/${identifier}/reactivate`);
  },
  addClient(data) {
    return simplePostRequest(`/client/new`, data);
  },
  editClient(identifier, data) {
    return simplePostRequest(`/client/${identifier}/edit`, data);
  },
  // Faq
  listFaqs(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetWithoutHeader(`/faq/list?${filterString}`);
  },
  getFaq(identifier) {
    return simpleGetRequest(`/faq/${identifier}/show`);
  },
  addFaq(data) {
    return simplePostRequest(`/adm/faq/new`, data);
  },
  editFaq(identifier, data) {
    return simplePostRequest(`/adm/faq/${identifier}/edit`, data);
  },
  deleteFaq(identifier) {
    return simpleDeleteRequest(`/adm/faq/${identifier}/remove`);
  },

  //Term Use
  listTermUse(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/adm/policy/term/list/all?${filterString}`);
  },
  currentTermUse() {
    return simpleGetWithoutHeader(`/adm/policy/term/current`);
  },
  addTermUse(data) {
    return simplePostRequest(`/adm/policy/term/new`, data);
  },
  editTermUse(identifier, data) {
    return simplePutRequest(`/adm/policy/${identifier}/edit`, data);
  },
  cancelTermUse(identifier) {
    return simplePutRequest(`/adm/policy/${identifier}/cancel`);
  },
  publishTermUse(identifier, data) {
    return simplePutRequest(`/adm/policy/${identifier}/publish`, data);
  },

  //Policy Privacy
  listPolicyPrivacy(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/adm/policy/privacy/list/all?${filterString}`);
  },
  currentPolicyPrivacy() {
    return simpleGetWithoutHeader(`/adm/policy/privacy/current`);
  },
  addPolicyPrivacy(data) {
    return simplePostRequest(`/adm/policy/privacy/new`, data);
  },
  editPolicyPrivacy(identifier, data) {
    return simplePutRequest(`/adm/policy/${identifier}/edit`, data);
  },
  cancelPolicyPrivacy(identifier, data) {
    return simplePutRequest(`/adm/policy/${identifier}/cancel`, data);
  },
  publishPolicyPrivacy(identifier, data) {
    return simplePutRequest(`/adm/policy/${identifier}/publish`, data);
  },

  // Process
  getFilters() {
    return simpleGetRequest(`/filter/list`, true);
  },

  listProcessKanban(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/process/list?${filterString}${paginationString}page_size=50`);
  },
  listProcess(filters, page, cancelTokenSource, pageSize = 10) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/process/list?${filterString}${paginationString}&page_size=${pageSize}`, false, cancelTokenSource);
  },
  countProcess(filters, cancelTokenSource) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/process/count?${filterString}`, false, cancelTokenSource);
  },
  changeProcessVisibility(identifier, data) {
    return simplePostRequest(`/process/${identifier}/process-visibility`, data);
  },

  listMap(data, page) {
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(
      `/map-filter/list-map?${paginationString}filters=${JSON.stringify(data) === '{}' ? null : JSON.stringify(data)}`
    );
  },
  listSelection(page) {
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/map-filter/list?${paginationString}`);
  },

  listProcessInconsistencies(identifier) {
    return simpleGetRequest(`/comex/process/validate/${identifier}/detail`);
  },

  listClientsUsers() {
    return simpleGetRequest(`/map-filter/client/users`);
  },
  deleteSelection(id) {
    return simpleDeleteRequest(`/map-filter/${id}/remove`);
  },
  newSelectionMap(values, filters, fields) {
    filters.fields = fields;
    let data = { ...values, filters };
    data.filters = JSON.stringify(data.filters);

    return simplePostRequest(`/map-filter/new`, data);
  },
  listImports(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/adm/data-update/list?${filterString}${paginationString}`);
  },
  showProcess(identifier) {
    return simpleGetRequest(`/process/${identifier}/show`);
  },
  calendar(calendarModality, dataModality, filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/calendar/process/${calendarModality}/${dataModality}?${filterString}`);
  },
  lastUpdates(filters, page, pageSize = 25) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(`/process/update/list?${filterString}${paginationString}page_size=${pageSize}`);
  },
  lastComments(filters, page, pageSize = 25) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(`/process/comment/list?${filterString}${paginationString}page_size=${pageSize}`);
  },
  favouriteComments(filters, page, pageSize = 25) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(`/process/comment/list/favourites?${filterString}${paginationString}page_size=${pageSize}`);
  },

  editProcessComment(processIdentifier, fileIdentifier, data) {
    return simplePostRequest(`/process/comment/${processIdentifier}/${fileIdentifier}/edit`, data);
  },
  removeProcessComments(processIdentifier, fileIdentifier) {
    return simpleDeleteRequest(`/process/comment/${processIdentifier}/${fileIdentifier}/remove`);
  },
  listUpdates(filters, page, pageSize = 25) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(`/process/update/list?${filterString}${paginationString}page_size=${pageSize}`);
  },
  sendAIQuestion(data) {
    return simplePostRequest(`/assistant-ai/question/ask`, data);
  },
  listAIMessages(page, pageSize = 9) {
    let paginationString = generatePaginationString(page);
    return simpleGetRequest(`/assistant-ai/question/list?${paginationString}page_size=${pageSize}`);
  },
  getAIMetrics() {
    return simpleGetRequest(`/assistant-ai/question/metrics`);
  },
  getProcessUpdates(identifier) {
    return simpleGetRequest(`/process/${identifier}/updates`);
  },
  getProcessComments(identifier, filters, cancelTokenSource) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/process/comment/${identifier}/list?${filterString}`, false, cancelTokenSource);
  },
  removeProcessComments(processIdentifier, fileIdentifier) {
    return simpleDeleteRequest(`/process/comment/${processIdentifier}/${fileIdentifier}/remove`);
  },
  addProcessStageComment(identifier, stage, data) {
    return simplePostRequest(`/process/comment/${identifier}/send?stage=${stage}`, data);
  },
  addProcessComment(identifier, data) {
    return simplePostRequest(`/process/comment/${identifier}/send`, data);
  },
  editProcessComment(processIdentifier, fileIdentifier, data) {
    return simplePostRequest(`/process/comment/${processIdentifier}/${fileIdentifier}/edit`, data);
  },
  getProcessItem(identifier, page) {
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/process/item/${identifier}/list?${paginationString}`);
  },
  getProcessQuote(identifier, page) {
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/process/quote/${identifier}/list?${paginationString}`);
  },
  getClientsQuote(identifier, page) {
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/process/quote/${identifier}/list?${paginationString}`);
  },
  getQuotes(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/quote/list?${filterString}${paginationString}`);
  },
  postRequestOperacional(identifier, data) {
    return simplePostRequest(`/quote/${identifier}/change-status`, data);
  },
  getQuoteShow(identifier = undefined) {
    return simpleGetRequest(`/quote/${identifier}/show`);
  },
  postQuoteEdit(identifier, data) {
    return simplePostRequest(`/quote/${identifier}/edit`, data);
  },
  postQuoteNew(data) {
    return simplePostRequest(`/quote/new`, data);
  },
  postQuoteDuplicate(identifier) {
    return simplePostRequest(`/quote/${identifier}/duplicate`);
  },
  saveQuoteDocsUploads(identifier, data, config) {
    return uploadFiles(`/quote/file/${identifier}/upload`, data, config);
  },
  getFilesListByQuote(identifier, page) {
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/quote/file/${identifier}/list?${paginationString}`);
  },
  removeQuoteFile(identifier, fileIdentifier) {
    return simpleDeleteRequest(`/quote/file/${identifier}/${fileIdentifier}/remove`);
  },
  getCurrency() {
    return simpleGetRequest(`/currency/list/quote`);
  },
  changeQuoteSolicitor(identifier) {
    return simplePostRequest(`/quote/${identifier}/bind-solicitor-user`);
  },
  postQuoteAssume(identifier) {
    return simplePostRequest(`/quote/${identifier}/bind-logistic-user`);
  },
  getUsersLogistic() {
    return simpleGetRequest(`/user/list?role[]=ROLE_LOGISTIC`);
  },
  getLogHistory(identifier, page, pageSize) {
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/quote/${identifier}/log/list?${pageSizeString}${paginationString}`);
  },

  //benchmark
  listBenchmarks(filters, page, cancelTokenSource) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/benchmark/list?${filterString}${paginationString}`, false, cancelTokenSource);
  },
  // Profile
  async getProfile() {
    const response = await api.get('/profile/show', {
      headers: {
        Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
      },
    });
    return {
      role: response.data.role,
      identifier: response.data.identifier,
      name: response.data.name,
      email: response.data.email,
      cellphone: response.data.cellphone,
      phone: response.data.phone,
      roleHumanReadable: response.data.roleHumanReadable,
      permissions: response.data.permissions,
      picture: response.data.profilePictureUri ? process.env.REACT_APP_API_URL + response.data.profilePictureUri : null,
    };
  },
  lastAccessProfile(identifier) {
    return simpleGetRequest(`/profile/${identifier}/last-access`);
  },
  editProfile(data) {
    return simplePostRequest(`/profile/edit`, data);
  },
  editPassword(data) {
    return simplePostRequest(`/profile/change-password`, data);
  },
  getProfileNotificationPreference() {
    return simpleGetRequest(`/profile/notifications`);
  },
  editProfileNotificationPreference(data) {
    return simplePostRequest(`/profile/notifications`, data);
  },
  changeProfileFacialStatus(identifier, action) {
    return simpleGetRequest(`/profile/${identifier}/facial/${action}`);
  },
  checkProcessPriority(identifier) {
    return simpleGetRequest(`/profile/favourite-process/${identifier}/check`);
  },
  listProcessPriority() {
    return simpleGetRequest(`/profile/favourite-process/list`);
  },
  saveProcessPriority(identifier, isPriority) {
    if (isPriority) {
      return simplePostRequest(`/profile/favourite-process/add`, {
        process: identifier,
      });
    } else {
      return simplePostRequest(`/profile/favourite-process/remove`, {
        process: identifier,
      });
    }
  },
  uploadClientCertificateFile(data, config) {
    return uploadFiles(`/client-certificate/upload`, data, config);
  },
  getCertificateList(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/client-certificate/list?${filterString}`);
  },
  removeCertificateFile(identifier) {
    return simpleDeleteRequest(`/client-certificate/${identifier}/remove`);
  },
  uploadProfilePicture(data) {
    return simplePostRequest(`/profile/picture`, data);
  },
  // User
  showUserDriver(identifier) {
    return simpleGetRequest(`profile/${identifier}/show`);
  },
  getUserRolesAction() {
    return simpleGetRequest(`/adm/user/role/list`);
  },
  listUsers(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/adm/user/list?${pageSizeString}${filterString}${paginationString}`);
  },
  listDrivers(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/adm/user/list/driver?${filterString}`);
  },
  listDriversAll(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/adm/user/list/driver?${pageSizeString}${filterString}${paginationString}`);
  },
  postCloneUser(identifier, clonedIdentifier, modality) {
    return simplePostRequest(`/adm/user/${identifier}/clone/${clonedIdentifier}/${modality}`);
  },
  exportCsvUser(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetBlobRequest(`/adm/user/export-csv?${filterString}`);
  },
  async showUser(identifier) {
    const data = await simpleGetRequest(`/adm/user/${identifier}/show`);
    return {
      role: data.role,
      identifier: data.identifier,
      name: data.name,
      email: data.email,
      cellphone: data.cellphone,
      phone: data.phone,
      roleHumanReadable: data.roleHumanReadable,
      picture: process.env.API_URL + data.profilePictureUri,
      hasAllClients: data.hasAllClients,
    };
  },
  deleteUser(identifier, filters) {
    let filterString = generateFilterString(filters);
    return simplePostRequest(`/adm/user/${identifier}/chage-status?${filterString}`);
  },
  addUser(modality, data) {
    return simplePostRequest(`/adm/user/${modality}/new`, data);
  },
  editUser(identifier, data) {
    return simplePostRequest(`/adm/user/${identifier}/edit`, data);
  },
  editUserPassword(identifier, data) {
    return simplePostRequest(`/adm/user/${identifier}/change-password`, data);
  },
  editUserRole(identifier, data) {
    return simplePostRequest(`/adm/user/${identifier}/change-role`, data);
  },
  changeStatusUser(identifier, status) {
    return simplePostRequest(`/driver/profile/${identifier}/change-status/${status}`);
  },
  changeStatusFileUser(identifier, status, data) {
    return simplePostRequest(`/driver/profile/file/${identifier}/change-status/${status}`, data);
  },
  changeStatusAdditionalUser(identifier, status, data) {
    return simplePostRequest(`/driver/profile/additional/${identifier}/change-status/${status}`, data);
  },
  showUserClients(identifier, filters, page, pageSize) {
    let filterString = generateFilterString({ ...filters, search: filters?.search });
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/adm/user/${identifier}/clients?${pageSizeString}${filterString}${paginationString}`);
  },
  showUserPermissions(identifier) {
    return simpleGetRequest(`/adm/user/${identifier}/permissions`);
  },
  saveProfilePermissions(identifier, data) {
    return simplePostRequest(`/adm/user/${identifier}/permissions`, data);
  },
  saveProfileClients(identifier, data) {
    return simplePostRequest(`/adm/user/${identifier}/clients`, data);
  },
  addAllProfileClients(identifier) {
    return simplePostRequest(`/adm/user/${identifier}/clients/all`, {});
  },
  removeAllProfileClients(identifier) {
    return simplePostRequest(`/adm/user/${identifier}/clients/remove-all`, {});
  },
  uploadUserPicture(identifier, data) {
    return simplePostRequest(`/adm/user/${identifier}/picture`, data);
  },
  addVehicle(data) {
    return simplePostRequest(`/adm/vehicle/new`, data);
  },
  editVehicle(identifier, data) {
    return simplePostRequest(`/adm/vehicle/${identifier}/edit`, data);
  },
  showVehicle(identifier) {
    return simpleGetRequest(`/adm/vehicle/${identifier}/show`);
  },
  exportCsvVehicle(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetBlobRequest(`/adm/vehicle/export-csv?${filterString}`);
  },
  //Stage
  getStagesList() {
    return simpleGetRequest('/stage/list');
  },
  // Ports
  getPortList() {
    return simpleGetRequest('/port/list');
  },
  //Docs Upload
  getProcessFileModalities(stageModality) {
    return simpleGetRequest(`/process/file/modality/list?stageModality=${stageModality}`);
  },
  getFilesListByProcess(identifier, page) {
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/process/file/${identifier}/list?${paginationString}`);
  },
  saveDocsUploads(identifier, data, config) {
    return uploadFiles(`/process/file/${identifier}/upload`, data, config);
  },
  // Processo Externo
  saveProcessManual(data, config) {
    return uploadFiles(`/process/file/manual`, data, config);
  },
  removeFile(processIdentifier, fileIdentifier) {
    return simpleDeleteRequest(`/process/file/${processIdentifier}/${fileIdentifier}/remove`);
  },
  async downloadFile(processIdentifier, fileIdentifier) {
    const response = await api.get(`process/file/${processIdentifier}/${fileIdentifier}/download?${new Date().getTime()}`, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
      },
    });
    return response.data;
  },
  async downloadFreightXml(identifier) {
    const response = await api.get(`/freight/${identifier}/download-cte?${new Date().getTime()}`, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: 'Bearer ' + (isClient ? window.localStorage.getItem('session-token') : ''),
      },
    });
    return response.data;
  },
  changeDriverFreightTrajectorySituation(identifier) {
    return simpleGetRequest(`/adm/trajectory/${identifier}/change-situation`);
  },
  uploadDriverFreightCteFile(data, config) {
    return uploadFiles(`/driver-freight/file/upload`, data, config);
  },
  uploadDriverFreightFile(identifier, data, config) {
    return uploadFiles(`/driver-freight/file/${identifier}/upload`, data, config);
  },
  uploadExpeditionDocument(data, config) {
    return uploadFiles(`/expedition/document/complex/upload`, data, config);
  },
  savePickingPackFile(data, config) {
    return uploadFiles(`/expedition/document/simple/upload`, data, config);
  },
  removeCTEFile(identifier, fileIdentifier) {
    return simpleDeleteRequest(`/driver-freight/${identifier}/file/${fileIdentifier}/remove`);
  },
  // Especificação da operação
  newOperationAction(data) {
    return simplePostRequest(`/operation-specification/new`, data);
  },
  listOperation(filters, page, pageSize = 10) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/operation-specification/list?${filterString}${paginationString}page_size=${pageSize}`);
  },
  listOperationResponsibleUsersAction(role) {
    return simpleGetRequest(`/operation-specification/user/${role}/list`);
  },
  listNcmsAction(code) {
    return simpleGetRequest(`/ncm/list?code=${code}`);
  },
  showOperationAction(identifier) {
    return simpleGetRequest(`/operation-specification/${identifier}/show`);
  },
  showOperation(identifier) {
    return simpleGetRequest(`/operation-specification/${identifier}/show`);
  },
  editOperationAction(identifier, data) {
    return simplePostRequest(`/operation-specification/${identifier}/edit`, data);
  },
  deleteOperation(identifier) {
    return simpleDeleteRequest(`/operation-specification/${identifier}/remove`);
  },
  importOperationDocumentsAction(identifier, data) {
    return simplePostRequest(`/operation-specification/${identifier}/document/upload`, data);
  },
  operationDownloadFileAction(identifier) {
    return simpleGetBlobRequest(`/open/operation-specification/${identifier}/file/download?${new Date().getTime()}`);
  },
  operationConsentAction(identifier) {
    return simpleGetRequest(`/operation-specification/${identifier}/consent`);
  },
  importOperationNcmsAction(data) {
    return simplePostRequest('/operation-specification/file/ncm/upload', data);
  },
  //Trips
  listTrips(identifier, modality) {
    return simpleGetRequest(`/driver/freight/${identifier}/list/${modality}`);
  },
  sendTrip(identifier, userIdentifier) {
    return simpleGetRequest(`/driver/freight/${identifier}/${userIdentifier}/send`);
  },
  listTripRoute(identifier) {
    return simpleGetRequest(`/driver/freight/${identifier}/route`);
  },
  reportDriver(identifier) {
    return simpleGetRequest(`/adm/freight/report/${identifier}/driver`);
  },

  // KPIs - Comex
  getProcessKpiMainChartData(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`dashboard/process/kpi/monetary-value-history?${filterString}`);
  },
  getProcessKpiLeadTimeTotalData(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`dashboard/process/kpi/lead-time/total?${filterString}`);
  },
  getProcessKpiLeadTimeOperationData(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`dashboard/process/kpi/lead-time/operation?${filterString}`);
  },
  getProcessKpiLeadTimeTransitData(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`dashboard/process/kpi/lead-time/transit?${filterString}`);
  },
  getProcessKpiLeadTimeDispatchData(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`dashboard/process/kpi/lead-time/dispatch?${filterString}`);
  },

  // KPIs - WMS
  getWarehouseKpiCurrentStockValue(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`dashboard/warehouse/kpi/current-stock-value?${filterString}`);
  },
  getWarehouseKpiCurrentStockLackOfProduct(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/dashboard/warehouse/kpi/current-stock-lack-of-product?${filterString}`);
  },
  getWarehouseKpiCurrentStockLackOfProductList(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/dashboard/warehouse/kpi/current-stock-lack-of-product-list?${filterString}`);
  },
  getWarehouseKpiStockCoverage(filters, itenPerPage) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`dashboard/warehouse/kpi/stock-coverage${itenPerPage && itenPerPage}?${filterString}`);
  },
  getWarehouseKpiStockTurnover(filters, itenPerPage) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`dashboard/warehouse/kpi/inventory-turnover${itenPerPage && itenPerPage}?${filterString}`);
  },
  getWarehouseKpiStockCoveragePerDay(filters, itenPerPage) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`dashboard/warehouse/kpi/stock-coverage-per-day${itenPerPage && itenPerPage}?${filterString}`);
  },

  // KPIs - Carbon
  getProcessMetrics(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/carbon-footprint/kpi/enumerate/process?${filterString}`);
  },

  getClientMetrics(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/carbon-footprint/kpi/enumerate/client?${filterString}`);
  },

  getTransportMetrics(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/carbon-footprint/kpi/enumerate/transport?${filterString}`);
  },

  getInternationalTransitMetrics(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/carbon-footprint/kpi/process?${filterString}`);
  },

  getWarehouseMetrics(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/carbon-footprint/kpi/warehouse?${filterString}`);
  },

  getNationalTransportMetrics(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/carbon-footprint/kpi/transport?${filterString}`);
  },

  getTotalMetricsClient(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/carbon-footprint/kpi/total?${filterString}`);
  },

  getMonthMetrics(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/carbon-footprint/kpi/month?${filterString}`);
  },

  // Report generator
  getListOfReportsMenu(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`report-generator/list?${filterString}`);
  },

  getSingleReportOfMenu(identifier, filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`report-generator/${identifier}/show?${filterString}`);
  },

  getListDefautParams() {
    return simpleGetRequest(`report-generator/list-default-parameters`);
  },

  getListDatabaseNames() {
    return simpleGetRequest(`report-generator/list-database-names`);
  },

  runReport(identifier, data) {
    return simplePostRequest(`report-generator/${identifier}/run`, data);
  },

  exportReport(identifier, modality, data) {
    if (modality !== 'csv' && modality !== 'xls' && modality !== 'xlsx') {
      modality = 'export';
    }

    return simplePostRequest(`report-generator/${identifier}/${modality}`, data);
  },

  createReport(data) {
    return simplePostRequest(`report-generator/create`, data);
  },

  deleteReport(identifier) {
    return simpleDeleteRequest(`report-generator/${identifier}/remove`);
  },

  editReport(identifier, data) {
    return simplePutRequest(`report-generator/${identifier}/edit`, data);
  },

  // Busca de lat long via endereço
  getGeolocationByAddress(address) {
    return simplePostRequest(`/location/search`, {
      address: address,
    });
  },

  // Busca de lat long via endereço
  getTrajectoryTripByAddress(origin, destination, waypoints) {
    return completePostRequest(`/location/trajectory`, {
      origin,
      destination,
      waypoints,
    });
  },
  addTrajectoryTripByAddress(origin, destination, waypoints, trajectoryGoogle) {
    return simplePostRequest(`/location/trajectory/new`, {
      origin,
      destination,
      waypoints,
      trajectoryGoogle,
    });
  },

  // Gestão de viagens Kanban
  // Preparação
  requestRefusedByDriver(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('REFUSED_BY_DRIVER', page, pageSize, filterString);
  },
  requestDraft(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('DRAFT', page, pageSize, filterString);
  },
  requestCreated(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('CREATED', page, pageSize, filterString);
  },
  requestSentToDriver(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('SENT_TO_DRIVER', page, pageSize, filterString);
  },
  requestAcceptedByDriver(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('ACCEPTED_BY_DRIVER', page, pageSize, filterString);
  },
  requestCancelled(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('CANCELLED', page, pageSize, filterString);
  },

  //Execução
  requestGoingToCollect(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('GOING_TO_COLLECT', page, pageSize, filterString);
  },
  requestReadToCollect(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('READ_TO_COLLECT', page, pageSize, filterString);
  },
  requestCollecting(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('COLLECTING', page, pageSize, filterString);
  },
  requestReadToTravel(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('READ_TO_TRAVEL', page, pageSize, filterString);
  },
  requestTravelling(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('TRAVELLING', page, pageSize, filterString);
  },
  requestHalted(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('HALTED', page, pageSize, filterString);
  },

  //Entrega
  requestArrivedAtDestination(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('ARRIVED_AT_DESTINATION', page, pageSize, filterString);
  },
  requestUnloading(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('UNLOADING', page, pageSize, filterString);
  },
  // TODO: Ver com PH se esse status vai ser necessário

  // requestDelivered(page, pageSize, filters) {
  //   return requestFreightByStatus('DELIVERED', page, pageSize, filterString);
  // },
  requestDeliverRefused(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('DELIVER_REFUSED', page, pageSize, filterString);
  },
  requestDeliverSignatureCollected(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('DELIVER_SIGNATURE_COLLECTED', page, pageSize, filterString);
  },
  requestDeliverSignatureRefused(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('DELIVERED_WITH_SIGNATURE', page, pageSize, filterString);
  },
  requestCompleted(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('COMPLETED', page, pageSize, filterString);
  },

  //Faturamento
  requestPaymentAllowed(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('PAYMENT_ALLOWED', page, pageSize, filterString);
  },
  requestPaid(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('PAID', page, pageSize, filterString);
  },
  requestPaidCancelled(page, pageSize, filters) {
    let filterString = generateFilterString(filters);
    return requestFreightByStatus('PAID_CANCELLED', page, pageSize, filterString);
  },

  //Fluxo de caixa

  getCashFlowTable(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/comex/cashflow?${filterString}`);
  },

  fetchDetailsByStatus(slug, filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/comex/cashflow/${slug}/detail?${filterString}`);
  },

  getInOutGraph(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/comex/cashflow/report?${filterString}`);
  },

  //Reativação de usuário
  async sendEmailUserReactivation(body) {
    return await api.post(`user-self-reactivate/request`, body);
  },

  async sendNewPasswordUserReactivation(body) {
    return await api.post(`/user-self-reactivate/send`, body);
  },

  async authLogin(body) {
    return await api.post(`/login/check`, body);
  },

  // Análise de Crédito
  listCreditAnalysis(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/credit-analysis/list?${pageSizeString}${filterString}${paginationString}`);
  },
  addCreditAnalysis(data) {
    return simplePostRequest(`/credit-analysis/new`, data);
  },
  showCreditAnalysis(identifier) {
    return simpleGetRequest(`/credit-analysis/${identifier}/show`);
  },
  addRequestMatrizCreditAnalysis(data, identifier, modality) {
    return simplePostRequest(`credit-analysis/${identifier}/request/${modality}`, data);
  },

  listContracts(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/contract/list?${pageSizeString}${filterString}${paginationString}`);
  },

  // Gestão de contratos
  listExepditionDocuments(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);
    let pageSizeString = generatePageSizeString(pageSize);

    return simpleGetRequest(`/expedition/document/list?${pageSizeString}${filterString}${paginationString}`);
  },
  addContract(data, config) {
    return uploadFiles(`/contract/new`, data, config);
  },
  editContract(identifier, data, config) {
    return uploadFiles(`/contract/${identifier}/edit`, data, config);
  },
  getContract(identifier) {
    return simpleGetRequest(`/contract/${identifier}/show`);
  },
  changeStatusContract(identifier, modality) {
    return simplePostRequest(`/contract/${identifier}/change-status/${modality}`);
  },

  // Rentabilidade
  getProfitability(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/profitability/list?${filterString}${paginationString}page_size=10`);
  },

  getEstimatedBilling(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/billing-analytics/list/estimated?${filterString}${paginationString}page_size=25`);
  },

  getInProgressBilling(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/billing-analytics/list/progress?${filterString}${paginationString}page_size=25`);
  },

  getBilledBilling(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/billing-analytics/list/billed?${filterString}${paginationString}page_size=25`);
  },

  getConsolidation(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/billing-analytics/consolidated?${filterString}`);
  },

  //Consulta tributária
  //Listagem de consulta tributária
  fetchTaxInquiryList(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let pageSizeString = generatePageSizeString(pageSize);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/tax-inquiry/list?${filterString}${pageSizeString}${paginationString}`);
  },

  //Visualizar uma consulta tributária
  fetchTaxQuery(identifier) {},

  //Cadastrar uma consulta tributária
  createTaxQuery(data) {
    return simplePostRequest(`/tax-inquiry/new`, data);
  },

  //Editar uma consulta tributária
  editTaxQuery(identifier, data) {
    return simplePostRequest(`/tax-inquiry/${identifier}/edit`, data);
  },

  //Alterar status de uma consulta tributária
  changeTaxQueryStatus(identifier, data) {
    return simplePostRequest(`/tax-inquiry/${identifier}/change-status`, data);
  },

  //Listar os produtos da consulta tributária
  getTaxQueryProducts(identifier) {
    return simpleGetRequest(`/tax-inquiry/${identifier}/product/list`);
  },

  //Listar as ncm's da consulta tributária
  getTaxQueryNCMs(identifier) {
    return simpleGetRequest(`/tax-inquiry/${identifier}/ncm/list`);
  },

  //Validação de consulta tributária
  validateTaxQuery(identifier) {
    return simplePostRequest(`/tax-inquiry/${identifier}/validate`);
  },

  //Listagem das options de cadastro/edição de consulta tributária
  getTaxQueryOptions() {
    return simpleGetRequest(`/tax-inquiry/list/fields`);
  },

  //Logs de consulta tributária
  historyTaxInquiry(page, pageSize) {
    let pageSizeString = generatePageSizeString(pageSize);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/tax-inquiry/log?${pageSizeString}${paginationString}`);
  },
  taxInquiryListNcmsAction(filters) {
    let filterString = generateFilterString(filters);
    return simpleGetRequest(`/ncm/list?${filterString}`);
  },

  //Gestão de NCM
  //Listagem de NCM's
  fetchNCMList(filters, page, pageSize) {
    let filterString = generateFilterString(filters);
    let pageSizeString = generatePageSizeString(pageSize);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/ncm/list?${filterString}${pageSizeString}${paginationString}`);
  },

  //Visualizar uma NCM
  fetchNCMQuery(identifier) {
    return simpleGetRequest(`/ncm/${identifier}/show`);
  },

  //Cadastrar uma NCM
  createNCMQuery(data) {
    return simplePostRequest(`/ncm/new`, data);
  },

  //Editar uma NCM
  editNCMQuery(identifier, data) {
    return simplePostRequest(`/ncm/${identifier}/edit`, data);
  },

  //Alterar status de uma NCM
  changeNCMQueryStatus(identifier, data) {
    return simplePostRequest(`/ncm/${identifier}/change-status`, data);
  },

  //Visualizar logs de uma NCM
  viewNCMLogs(identifier) {
    return simpleGetRequest(`/ncm/${identifier}/history/list`);
  },

  getInconsistentProcesses(filters, page) {
    let filterString = generateFilterString(filters);
    let paginationString = generatePaginationString(page);

    return simpleGetRequest(`/comex/process/validate?${filterString}${paginationString}`);
  },

  getChartInconsistentProcesses(filters) {
    let filterString = generateFilterString(filters);

    return simpleGetRequest(`/comex/process/validate/report?${filterString}`);
  },

  // 2fa
  generateCodeTwoFactor() {
    return simplePostRequest(`/2fa/request`);
  },
  validateCodeTwoFactor(data) {
    return simplePostRequest(`/2fa/send`, data);
  },
};

export default requests;
